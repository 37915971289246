.button-set {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.back-button {
    font-size: 14px;
    cursor: pointer;
}

.back-arrow {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
  }
  
  .arrow-icon {
    width: 24px;
    height: 24px;
    color: #000;
  }
  
  .description {
    font-size: 12px;
  }

  .custom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    margin-top: 20px;
  }

  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    border-radius: 20px;
    border: none;
  }
  
  .custom-range::-moz-range-track {
    width: 100%;
    height: 10px;
    border-radius: 20px;
    border: none;
  }
  
  .custom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    background: #0088cc;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -10px;
  }
  
  .custom-range::-moz-range-thumb {
    height: 30px;
    width: 30px;
    background: #0088cc;
    border-radius: 50%;
    cursor: pointer;
  }

  .active-slider input[type="range"]::-webkit-slider-thumb {
    background: #f0f0f0; /* Change thumb color when a button is pressed */
  }
  
  .active-slider input[type="range"]::-moz-range-thumb {
    background: #f0f0f0; /* Change thumb color when a button is pressed for Firefox */
  }

  .active-slider input[type="range"]::-webkit-slider-runnable-track {
    background: #f0f0f0;
  }
  
  .active-slider input[type="range"]::-moz-range-track {
    background: #f0f0f0;
  }

.button-group.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.button-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.traffic-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  width: 100%;
}

.traffic-button.active {
  background-color: #0088cc;
  color: white;
}

.description-text {
  font-size: 14px;
  color: #6a6a6a;
}
